.profile-image {
  aspect-ratio: 1;
}

.image-section {
  width: 100%;
  max-width: 641px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &>* {
    width: 100%;
  }

  .image-input-section {
    text-align: center;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    & .image-placeholder {
      width: 100%;
    }

    &>* {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $text-box-fill;
      border: 1px solid $primary-outline;
      border-radius: 5px;
    }

    .edit-photo-icon {
      height: 50px;
      width: 50px;
      font-size: 50px;
      position: absolute;
      bottom: -25px;
      right: -25px;
      background-color: rgba(241, 242, 235, 1);
      color: rgba(28, 41, 17, 1);
      cursor: pointer;
    }

    & .image-input {
      display: none;
    }

    & .input-error {
      border: 1px solid $error-box-outline;
      color: $error-box-outline;
      background-color: $error-box-fill;
      margin-bottom: 10px;
    }

    .image-preview {
      position: relative;
      border-radius: 5px;
      height: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      overflow: hidden;
    }

    .presentational {
      cursor: default;
    }
  }
}