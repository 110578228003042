.sup-label {
  font-size: 16px;
  margin-bottom: 5px;
}

.sub-label {
  font-family: 'avenir-regular';
  font-size: 16px;
  margin: 0 0 5px;
  word-break: break-all;
  overflow-wrap: break-word;
}