// Modal background overlay
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  // opacity: 0;
  // visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  .modal {
    height: 80%;
    width: 80%;

    .crop-container {
      height: 90%;
      width: 90%;
      display: flex;
      flex-direction: column;
      background-color: rgba(104, 100, 100, 1);
      border-radius: 2%;

      .reactEasyCrop_Container {
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      .button-actions {
        height: 20%;
        position: relative;
        margin-top: 10px;
        display: flex;
        gap: 50px;
        justify-content: center;
        align-items: center;

        &>* {
          height: 40%;
          border: none;
          border-radius: 5px;
        }

        .apply-crop-button,
        .cancel-crop-button {
          cursor: pointer;
        }

      }
    }
  }
}