// * Mobile Default
.upload-blog-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px;
}

.upload-blog-form {
  width: 100%;
}

// Tablet & Desktop
@media only screen and (min-width: 641px) {
  .upload-blog-form {
    width: 100%;
    max-width: 641px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: calc((100% - 641px) / 2);
    padding-right: calc((100% - 641px) / 2);
  }
}