@import '../../variables';

// * Mobile Default

.blog-review-card {
  background-color: $accent-color;
  padding: 16px;
  border-radius: 10px;
  .blog-review-card-recommendation {
    display: flex;
    gap: 16px;
    align-items: center;

    p {
      margin: 0;
    }

    .recommendation-bubble {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 4px 8px;
      border-radius: 100px;
      background-color: $light-background;
    }
  }

  .blog-review-card-content {
    margin: 16px 0 0;

    p {
      margin: 0;
    }
  }
}

// * Tablet
@media (min-width: 641px) {
  .blog-review-card {
  }
}

// * Desktop
@media only screen and (min-width: 1030px) {
  .blog-review-card {
  }
}
