.signup-page {
  height: 98%;
  width: 90%;
  padding: 0% 5%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

// desktop
@media only screen and (min-width: 1030px) {
  .signup-page {
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
  }
}
