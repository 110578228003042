//mobile and desktop
.layout {
  position: relative;
  padding-bottom: 3vh;

  & .content {
    position: relative;
  }

  .home-flow {
    min-height: 97vh;
    background-color: lightred;
    padding: 0 3%;
  }

  .auth-flow {
    height: 100dvh;
    background-image: url('../images/Desktop_Leaf_Background.jpg');
    background-size: cover;
  }

  .side-drawer-backdrop {
    z-index: 50;
  }

  // desktop
  @media only screen and (min-width: 1030px) {
    .home-flow {
      margin-left: 20%;
    }

    .side-drawer-backdrop {
      display: none;
    }
  }
}

.center-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .duck-loader {
    width: calc(6 * 30px);
    height: 50px;
    display: flex;
    color: $duck-feet;
    filter: drop-shadow(30px 25px 0 currentColor) drop-shadow(60px 0 0 currentColor) drop-shadow(120px 0 0 currentColor);
    clip-path: inset(0 100% 0 0);
    animation: l13 1.5s infinite steps(7);
  }

  .duck-loader:before {
    content: '';
    width: 30px;
    height: 25px;
    background: radial-gradient(farthest-side at right, currentColor 92%, #0000) left/20px 100% no-repeat,
      radial-gradient(farthest-side, currentColor 92%, #0000) right/17px 9px repeat-y;
  }

  @keyframes l13 {
    100% {
      clip-path: inset(0 -30px 0 0);
    }
  }
}