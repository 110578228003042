// mobile
.login-page {
  height: 100%;
  width: 90%;
  padding: 0% 5% 0% 5%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & .footer-link {
    text-align: center;
    margin-bottom: 10px;
    margin-top: auto;
    color: grey;
  }
}

// tablet
@media (min-width: 641px) {
  .login-page {
    align-items: center;

    & .footer-link {
      display: none;
    }
  }
}

// desktop
@media only screen and (min-width: 1030px) {
  .login-page {
    height: 100%;
    width: 100%;
    padding: 0%;
    flex-direction: row;
    background-size: 45%;
  }
}
