// mobile & tablet
.auth-heading-wrapper {
  display: flex;
  margin: 20px auto 20px 0px;
  align-items: center;
}

.home-heading-wrapper {
  max-height: 100px;
  align-self: stretch;
  padding: 16px 0 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & .hamburger-menu-wrapper {
    display: flex;
    justify-content: left;
    align-content: left;
    margin-right: 10px;

  }

  & .home-heading-text {
    display: none;
  }

  & .login-logout-heading-button {
    width: 25%;

    .login-nav-button,
    .logout-button {
      width: auto;
      font-family: 'Comfortaa', sans-serif;
      font-weight: bold;
      font-size: 15px;
      margin: 0;
      background: $action-color;
      border: none;
      color: white;
      text-align: right;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: $tablet-width) {
  .home-heading-wrapper {
    width: 100%;

    & .home-heading-text {
      display: flex;
    }
  }
}

// desktop
@media only screen and (min-width: $desktop-width) {
  .auth-heading-wrapper {
    display: flex;
    margin: 0px auto 5px 0px;
    align-items: center;
  }

  .home-heading-wrapper {

    .hamburger-menu-wrapper {
      display: none;
    }
  }

  .hamburger-menu-wrapper {
    display: none;
  }
}