.text-area-input-wrapper {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .area-input-label-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    p {
      margin: 0 0 0 10px;
      color: $character-limit-warning;
      font-size: 14px;
    }

    .char-limit-exceeded {
      color: $error-text-color;

    }
  }

  & .wilderNet-text-area-input {
    height: 4.5em;
    padding: 10px;
    background-color: $text-box-fill;
    border: 1px solid $primary-outline;
    border-radius: 10px;
  }

  & .input-error {
    border: 1px solid $error-box-outline;
    color: $error-box-outline;
    background-color: $error-box-fill;
  }

  & .wilderNet-text-area-input:focus {
    outline: none;
  }
}