// SCSS variables
$text: #000000;
$light-background: #fefff7;
$primary-color: #486332;
$action-color: #643123;
$accent-color: #dfe9d6;

//less common colors
$primary-outline: #1c2911;
$secondary-outline: #c8c8c8;
$text-box-fill: #f1f2eb;
$dark-brown: #24110c;
$grey-text: #30302f;

//least common colors
$post-content-box: #f1f2eb;
$secondary-content: #cdd6c5;
$menu-target-box: #ffffff;

//warning colors
$character-limit-warning: #858585;
$error-text-color: #ff0000;
$error-box-fill: #fff5f5;
$error-box-outline: #ff0000;
$warning: #ffd600;

$tablet-width: 641px;
$desktop-width: 1030px;

//loader
$duck-feet: #8d7958;

//css variables for use at runetime

:root {
  //main colors
  --text: #{$text};
  --light-background: #{$light-background};
  --primary-color: #{$primary-color};
  --action-color: #{$action-color};
  --accent-color: #{$accent-color};

  //less common colors
  --primary-outline: #{$primary-outline};
  --secondary-outline: #{$secondary-outline};
  --text-box-fill: #{$text-box-fill};
  --dark-brown: #{$dark-brown};
  --grey-text: #{$grey-text};

  //least common colors
  --post-content-box: #{$post-content-box};
  --secondary-content: #{$secondary-content};
  --menu-target-box: #{$menu-target-box};

  //warning colors
  --character-limit-warning: #{$character-limit-warning};
  --error-text-color: #{$error-text-color};
  --error-box-fill: #{$error-box-fill};
  --error-box-outline: #{$error-box-outline};
  --warning: #{$warning};

  --tablet-width: #{$tablet-width};
  --desktop-width: #{$desktop-width};

  //loader
  --duck-feet: #{$duck-feet};
}