/* Mobile Default */
.blog-discussion-list-wrapper {
  .blog-discussion-list-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
  }

  .blog-discussion-list-form input,
  .blog-discussion-list-form textarea {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Comfortaa', sans-serif;
    outline-color: $primary-outline;
  }

  .blog-discussion-list-form textarea {
    height: 120px;
    resize: vertical;
  }

  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }


}