.login-signup-component {
  width: 90%;
  max-width: 500px;
  padding: 0% 5% 5% 5%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: 1px;

  & .login-signup-title {
    margin-top: 0px;
    text-align: center;
  }
}

.login-signup-form {
  width: 100%;
}

// tablet
@media (min-width: 641px) {
  .login-signup-component {
    background-color: $secondary-content;
    min-height: 40%;
    max-height: 100%;
    border-radius: 10px;
    margin: 5% 3%;
    padding: 24px;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    & .login-signup-title {
      margin: 10px;
      text-align: center;
    }
  }
}

// // desktop
@media only screen and (min-width: 1030px) {
  .login-signup-component {
    padding: 2%;
    width: 36%;
    max-width: 40%;
    min-height: 0%;
  }
}