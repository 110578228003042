  .discussion-details-wrapper {
    white-space: pre-line;
    width: 90%;

    .discussion-parent {

      background-color: $accent-color;
      padding: 25px;
      border-radius: 10px;
      margin: 10px;
      list-style-type: none;
      width: 90%;
      word-wrap: break-word;

      .discussion-details-blog-title {
        text-decoration: none;
      }

      .discussion-details-blog-title:hover {
        text-decoration: underline;
      }

      .discussion-text {
        font-family: 'Comfortaa', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 200% */
        margin: 0 0 0 0;
        resize: none;
        /* Prevent manual resizing */
        overflow: hidden;

      }

      .discussion-user-time {
        font-family: 'Comfortaa', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #777;
        margin-top: 12px;

      }


    }

    .discussion-comment-form textarea {
      width: 88%;
      padding: 25px;
      margin: 8px;
      border: 1px solid #ddd;
      border-radius: 10px;
      font-family: 'Comfortaa', sans-serif;
      // outline-color: $primary-outline;
    }

    .login-warning {}

    .comment-count {
      font-size: larger;
    }

    .discussion-comments {


      .discussion-comment-card {
        list-style-type: none;
        word-wrap: break-word;
        width: 100%;
        padding: 25px;
        margin: 5px;
        border-radius: 10px;
        font-size: 16px;
        font-family: 'Comfortaa', sans-serif;
        background-color: $accent-color;


        .discussion-comment-card-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          color: #777;
          margin-top: 12px;


        }




      }

    }

  }