.progress-bar-container {
  width: 97%;
  padding: 5px;
  text-align: center;


  .progress-bar {
    width: 100%;
    height: 20px;
    background-color: $accent-color;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    .progress-bar-fill {
      height: 100%;
      background-color: $action-color;
      transition: width 0.4s ease-in-out;

      &.completed {
        background-color: $primary-color;
      }
    }


  }

  .progress-text {
    margin-top: 5px;
    font-size: 12px;
    /* Adjust the font size here */
  }
}