.cta-preserve {
  margin: 16px 0 8px 0;

  background-color: $accent-color;
  border-color: $primary-outline;

  border-radius: 10px;

  width: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);

  & > * {
    margin: 8px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
  }

  @media only screen and (max-width: 1030px) {
    p {
      display: none;
    }
  }

  & > div {
    padding: 0 32px;

    h2 {
      text-align: center;
    }
  }
}

.donate-cta-message {
  display: none;
}

@media only screen and (min-width: 641px) {
  .donate-cta-message {
    display: flex;
  }
}
