// tag group for desktop - blog preview
.tag-group {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  bottom: 0;
  gap: 8px;
  margin: 10px 0 0;
  overflow-x: auto;
  scrollbar-width: none;

  .tag-item {
    padding: 0px 8px;
    border-radius: 8px;
    background: $primary-color;
    margin-bottom: 0px;
    margin-top: auto;
  }

  .interest-card {
    height: 30px;
    white-space: nowrap;
    font-size: smaller;
    cursor: default;
  }

  .clickable {
    cursor: pointer;

    &:hover {
      background: lighten($primary-color, 10%);
      transition: background-color 0.3s ease;
    }
  }
}

.tag-group::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.ellipsis {
  margin-bottom: 0px;
  margin-top: auto;
}


// tablet
@media (min-width: 641px) {
  // .tag-group-wrapper {}
}

// desktop
@media only screen and (min-width: 1030px) {
  // .tag-group-wrapper {}
}