.button-container {
  margin: 20px;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &>* {
    width: 40%;
    min-width: 50px;
  }
}

.button-primary,
.button-secondary {
  background-color: $action-color;
  color: white;
  border-radius: 9999px;
  text-align: center;
  font-size: large;
  font-weight: 700;
  display: block;
  padding: 10px 20px;
  margin: 0px auto;
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  flex: none;

  &:disabled {
    background-color: rgba(100, 49, 35, 0.5);
    cursor: default;
  }

  &.button-primary:not(:disabled):hover {
    background: lighten($action-color, 10%);
    transition: background-color 0.3s ease;
  }

  &.button-secondary:not(:disabled):hover {
    background: darken(white, 10%);
    transition: background-color 0.3s ease;
  }
}

.button-full-width {
  width: 100%;
}

/* magic number width based on tailwindcss width valeus */
.button-medium-width {
  width: 208px;
}

.button-secondary {
  background-color: white;
  color: $action-color;
}

.edit-blog-button {
  background-color: unset;
  border: none;
}

.edit-blog-button:hover {
  text-decoration: underline;
  cursor: pointer;
}