.pagination {
  display: flex;
  justify-content: center; /* Center the pagination */
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  text-decoration: none;
  color: $light-background;
  background-color: $primary-color;
  padding: 10px 15px;
  border: 1px solid $secondary-content;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.pagination a:hover {
  color: $primary-color;
  background-color: $light-background;
}

.pagination .selected a {
  color: $primary-color;
  background-color: $light-background;
}

.pagination .disabled a {
  background-color: #ccc;
  pointer-events: none;
  cursor: not-allowed;
}
