.donate-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donate-sections {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  // #first-form {
  //   // flex: 1 1 100%;
  //   flex-basis: 100%;
  //   & > div {
  //     display: flex;
  //     justify-content: center;
  //   }
  // }
}

.donate-section {
  width: 100%;
  background-color: $secondary-content;
  border-radius: 10px;
  padding: 2%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subscription-select,
.donate-input {
  border-radius: 8px;
  padding: 5px;
  border: 1px solid #ccc;
  width: 100%;
  /* Ensures input and select are full width */
  margin-bottom: 10px;
  text-align: right;
  /* Adds space between input fields */
}

.donate-button-group {
  display: flex;
  gap: 10px;
  /* Adjust space between buttons */
  justify-content: center;
  /* Center align buttons within the form */
  margin-top: 10px;
  /* Add space between the buttons and other elements */
}

.donate-button {
  margin: 8px auto;
  padding: 8px 15px;
  display: block;
  max-width: 150px;
  /* Set max-width for smaller buttons */
}

.donate-form .button-primary,
.donate-form .button-secondary {
  margin-top: 8px;
  width: 100%;
}

/* Remove spinner arrows */
.donate-input::-webkit-outer-spin-button,
.donate-input::-webkit-inner-spin-button,
.donate-input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
  /* Firefox */
}

@media only screen and (min-width: 641px) {
  // .donate-sections {
  //   flex-direction: row;
  //   justify-content: space-between;
  // }

  .donate-section {
    width: 100%;
    background-color: $secondary-content;
    border-radius: 10px;
    padding: 2%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (min-width: $desktop-width) {}

.donation-message-section {
  background-color: white;
  border: 2px solid $primary-color;
  border-radius: 10px;
  padding: 20px;
  width: 85%;
  margin: 20px 0;
  text-align: center;
  max-width: 400px;
}

.donation-message-section h3 {
  font-size: 24px;
  color: $action-color;
  font-weight: bold;
  margin-bottom: 12px;
}

.donation-message-section h5 {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}