/* Ensure the main page container doesn't exceed 750px */
.newsfeed-page {
  max-width: 750px;
  margin: 0 auto;
  /* Center the page */
}

/* Adjust card container to ensure the cards are responsive and aligned */
.blogcard-wrapper {
  height: auto;
  /* Auto height to fit content */
}

.blogcard-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.newsfeed-blogcard {
  margin-bottom: 20px;

}