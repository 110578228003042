@import '../variables';

// Mobile Default
.blog-card-large {
  height: 98%;
  aspect-ratio: 1;
  flex-direction: column;
  margin: 0.5%;
  border-radius: 10px;
  background-color: $secondary-content;
  box-shadow: 4px 12px 12px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;

  .blog-card-action-area {
    height: 100%;
    background-color: $secondary-content;
    font-size: unset;
    font-style: unset;
    pointer-events: none;
    cursor: pointer;
  }

  & .fab-new-tab {
    margin: 0;
    position: absolute;
    scale: 80%;
    margin: 3px;
    background-color: rgb(116, 139, 139);
    z-index: 50;

    &:hover {
      background-color: rgb(125, 193, 193);
    }
  }

  .blog-card-img {
    width: 100%;
    height: 60%;
    aspect-ratio: 1;
    object-fit: cover;
    background: $character-limit-warning;
  }

  .blog-card-content {
    height: 36%;
    width: 96%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;
    background-color: $secondary-content;

    & > * {
      text-align: left;
      text-overflow: ellipsis;
    }

    & > h5 {
      height: 20%;
      width: 100%;
      overflow-y: clip;
      font-weight: 900;
      text-overflow: ellipsis;
    }

    & > p {
      height: 30%;
      max-height: 30%;
      width: 98%;
      margin-bottom: 0px;
      font-size: x-small;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow-y: clip;
      white-space: normal;
    }

    & > div {
      height: 30%;
    }
  }
}

// // tablet
// @media (min-width: 641px) {
//   .blog-card {
//     & .next-layer {
//       display: flex;
//       max-width: 360px;
//       flex-direction: column;
//       align-items: flex-end;
//       // gap: 8px;
//       border-radius: 10px;
//       background: $accent-color;
//       box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
//       margin: 40px;
//     }

//     .blog-card__img {
//       width: 100%;
//       height: 200px;
//       object-fit: cover;
//       border-radius: 10px 10px 0px 0px;
//       background: #a7a7a7;
//     }

//     .blog-card__content {
//       display: flex;
//       padding: 8px 16px 16px 16px;
//       flex-direction: column;
//       align-items: flex-start;
//       gap: 16px;
//       align-self: stretch;
//     }
//   }
// }

// // desktop
// @media only screen and (min-width: 1030px) {
//   .blog-card {
//     & .next-layer {
//       display: flex;
//       max-width: 360px;
//       flex-direction: column;
//       align-items: flex-end;
//       // gap: 8px;
//       border-radius: 10px;
//       background: $accent-color;
//       box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
//       margin: 40px;
//     }
//   }
// }
