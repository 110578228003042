.side-drawer {
  position: fixed;
  top: 0;
  height: 100%;
  left: -85%;
  width: 0%;
  background-color: white;
  border-right: 1px solid $secondary-content;
  box-shadow: 1px 0px 1px $primary-color;
  z-index: 100;
  overflow-y: auto;
  transition: left 0.5s ease-in-out, width 0.5s ease-in-out;

  .heading-logo {
    display: block;
    width: 80px;
    margin: 20px;
    border: none;
    border-radius: 2px;
  }

  .side-drawer-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .side-drawer-terms-link {
      /* nav-icon height + side-drawer-button margin */
      padding-bottom: calc(20px + 2%);

      a {
        text-decoration: none;

        * {
          font-size: 12px;
          padding: 0px;
        }
      }
    }
  }
}

.side-drawer.open {
  left: 0;
  width: 85%;
}

@media (min-width: 641px) {
  .side-drawer.open {
    width: 50%;
  }
}

@media only screen and (min-width: 1030px) {

  .side-drawer.open,
  .side-drawer-home-flow {
    left: 0%;
    width: 20%;

    .heading-logo {
      margin: 5% calc(4% + 10px) 5%;
    }
  }
}