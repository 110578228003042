.blog-discussion-card {
  background-color: $accent-color;
  padding: 16px;
  border-radius: 10px;
  margin: 10px 0;
  list-style-type: none;
  white-space: pre-line;

}

.discussion-link {
  text-decoration: none;
}

.blog-discussion-card-title {
  font-family: 'Comfortaa', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.blog-discussion-card-content {
  margin-bottom: 12px;

  .blog-discussion-content-text {
    font-family: 'Comfortaa', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    // color: #555;
  }
}

.blog-discussion-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #777;
  margin-top: 12px;


  .blog-discussion-card-user {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 700;
  }


}

.blog-discussion-card-time,
.comment-count {
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 100;

}