// Add Utils Styles
@import 'variables';

// Add Higher Order Component Styles
@import 'hoc/layout';
@import 'hoc/side-drawer';
@import 'hoc/backdrop';
@import 'hoc/search-component';
@import 'hoc/success-modal';

// Add Container Styles
@import 'containers/create-profile-page';
@import 'containers/login-page';
@import 'containers/login-signup-page';
@import 'containers/signup-page';
@import 'containers/home-page';
@import 'containers/blog-page';
@import 'containers/donate-page';
@import 'containers/donation-success-page';
@import 'containers/profile-page';
@import 'containers/upload-blog-page';
@import 'containers/upload-blog-review-page';
@import 'containers/blog-review-page';
@import 'containers/contact_us_page';
@import 'containers/newsfeed-page';

// Add Component Styles
@import 'components/button';
@import 'components/logo';
@import 'components/input';
@import 'components/heading';
@import 'components/labels';
@import 'components/tags';
@import 'components/find-a-blog-search';
@import 'components/blog-card-large';
@import 'components/blog-card-newsfeed';
@import 'components/blog-card-small';
@import 'components/blog-list-wrapper';
@import 'components/blog-page/blog-discussion-list-wrapper';
@import 'components/blog-page/blog-discussion-list-discussion-card.scss';
@import 'components/blog-page/blog-discussion-newsfeed-discussion-card.scss';
@import 'components/blog-page/discussion-details';
@import 'components/blog-page/blog-review-card.scss';
@import 'components/blog-page/blog-review-newsfeed-card.scss';
@import 'components/blog-page/blog-review-list-wrapper';
@import 'components/spinner';
@import 'components/cta-preserve';
@import 'components/auth-components/blogs-preview-component';
@import 'components/auth-components/login-signup-component';
@import 'components/navigation-components/side-drawer-button';
@import 'components/image-cropper-modal';
@import 'components/wn-components/wn-image-section';
@import 'components/wn-components/wn-tag-section';
@import 'components/wn-components/wn-text-area-section';
@import 'components/wn-pagination';
@import 'components/search-results';
@import 'components/progress-bar';

// Add Layout Styles
@import 'fonts/typography';

@font-face {
  font-family: 'avenir-demibold';
  src: url('./fonts/avenir-next-demibold.ttf');
}

@font-face {
  font-family: 'avenir-regular';
  src: url('./fonts/AvenirNextLTPro-Regular.otf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: 'avenir-demibold';
  -webkit-font-smoothing: antialiased;
}

a {
  color: black;
}