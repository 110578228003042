h1 {
  font-family: 'Comfortaa', sans-serif;
  font-size: 32px;
  font-weight: 400;
  // margin: 0;
}

h2 {
  font-family: 'Comfortaa', sans-serif;
  font-weight: 400;
  font-size: 24px;
}

h3 {
  font-family: 'Comfortaa', sans-serif;
  font-weight: 400;
  font-size: 22px;
  margin: 0;
}

h4 {
  font-family: 'Comfortaa', sans-serif;
  font-weight: 400;
  font-size: 20px;
}

h5 {
  font-family: 'Avenir', sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

h6 {
  font-family: Comfortaa;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 200% */
  margin: 0 0 0 0;
}

.body1 {
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.body2 {
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.body3 {
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  font-size: 14px;
}