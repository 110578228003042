.search-wrapper {
  display: flex;
  flex-direction: row;
  background-color: $text-box-fill;
  border: 1px solid black;
  outline: none;
  padding: 10px;
  margin-bottom: 0px;
  border-radius: 10px;
  cursor: pointer;

  .search-input {
    margin-left: 5px;
    background-color: inherit;
    outline: inherit;
    width: 100%;
    border: none;
  }
}
