// * mobile default
.blog-page {
  .blog-page-content-wrapper {
    max-width: 800px;
    margin: auto;
  }

  .button-primary {
    margin: 16px auto;
  }

  .css-0 {
    max-width: 640px;
    width: 100%;
  }

  .body1 {
    margin: 0;
  }

  .blog-page-header-content-wrapper {
    h1 {
      margin: 8px 0;
    }

    .blog-page-blog-title-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .view-blog-button {
        margin: 8px 0;
      }
    }

    .blog-page-header-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      img {
        width: 100%;
        aspect-ratio: 2;
        object-fit: cover;
      }
    }
  }

  .blog-page-list-content-tabs-wrapper {
    width: 100%;
  }
}

.blog-page-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
}

// * tablet
@media (min-width: 641px) {
  .blog-page {
    .blog-page-header-content-wrapper {
      .blog-page-blog-title-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        .view-blog-button {
          margin: 8px 0;
        }
      }

      .blog-page-header-image {
        margin: 8px 0;

        img {
          width: 100%;
          aspect-ratio: 2;
          object-fit: cover;
        }
      }
    }
  }
}

// * desktop
@media only screen and (min-width: 1030px) {}