@import '../variables';

.upload-review-page {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-review-component {
    width: 80%;
    max-width: 440px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 0 8px 16px 8px;

    .upload-review-card {
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
      background: $accent-color;
      border-radius: 10px;
      margin-bottom: 16px;

      .upload-review-image {
        width: 100%;
        aspect-ratio: 2;
        border-radius: 10px 10px 0px 0px;
      }

      .upload-review-content {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .upload-review-buttons {
      margin: 20px;
      gap: 8px;
      display: flex;
      flex-wrap: wrap;

      &>* {
        width: 40%;
        min-width: 100px;

      }
    }
  }
}