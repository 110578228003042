/* Styling for each individual newsfeed card to ensure consistent width */
.blog-discussion-newsfeed-card-link {
  text-decoration: none;
}


.blog-discussion-newsfeed-card {
  background-color: $accent-color;
  padding: 16px;
  border-radius: 10px;
  margin: 10px 0;
  list-style-type: none;
  white-space: pre-line;
  text-decoration: none;

  /* Make the card take full width of the container */
  /* Limit the width to 720px or less */
}


/* Optional: You can also set a min-width if you want to avoid very small cards on smaller screens */
.blog-discussion-newsfeed-card {
  min-width: 320px;
  /* Optional minimum width */
}

.blog-title-discussion-card {
  text-decoration: none;
}

.blog-title-discussion-card:hover {
  text-decoration: underline;
  transition: font-weight 0.2s ease-in-out;
}

/* Title styling */
.discussion-title {
  font-family: 'Comfortaa', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

/* Content styling */
.discussion-content p {
  font-family: 'Comfortaa', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

/* Footer styling */
.discussion-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #777;
  margin-top: 12px;
}


.discussion-user:hover {}