.logo-wrapper {
  width: 95%;
  height: 25%;
  min-height: 150px;
  margin-bottom: 24px;
  background-image: url('../images/Logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.logo-wrapper.donate {
  height: 30%;
}

@media only screen and (min-width: 641px) {
  .logo-wrapper {
    margin-top: 10px;
    height: 40%;
  }
}
