.blog-list-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;

  .blog-list-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: inherit;

    @media only screen and (max-width: $tablet-width) {
      h2:has(+ a.see-all-blogs) {
        font-size: 20px;
      }

      a.see-all-blogs {
        font-size: 12px;
      }
    }
  }

  .blog-list-one-line-results {
    left: 3%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-width: none;
    padding-bottom: 16px;
  }

  .blog-list-one-line-results::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
  }

  .blog-list-all-results {
    display: grid;
    grid-template-columns: repeat(5, auto);
  }

  .see-all-blogs {
    text-decoration: none;
    align-self: inherit;
    margin-left: 12px;
    padding: 5px 10px;
    font-size: medium;

    &:hover {
      text-decoration: underline;
    }
  }
}

.saved-blogs-component {
  display: flex;
  flex-direction: column;
  align-items: center;

  &>* {
    min-height: 260px;
    max-width: 400px;
    margin: 20px 0px;
    cursor: pointer;
  }
}

.blog-list-wrapper-all-results {
  display: flex;
  justify-content: center;
  z-index: 5;
  padding-top: 24px;

  .blog-list-all-results {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }

  @media only screen and (max-width: $tablet-width) {
    .blog-card-small {
      width: 150px;
    }
  }

  @media only screen and (min-width: $tablet-width) {
    .blog-list-all-results {
      grid-template-columns: repeat(3, auto);
    }
  }

  @media only screen and (min-width: 1260px) {
    .blog-list-all-results {
      grid-template-columns: repeat(4, auto);
    }
  }

  @media only screen and (min-width: 1600px) {
    .blog-list-all-results {
      grid-template-columns: repeat(6, auto);
    }
  }

  @media only screen and (max-width: 380px) {
    .blog-list-all-results {
      grid-template-columns: repeat(1, auto);
    }
  }
}