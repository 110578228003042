@import '../variables';

.input-wrapper {
  width: 100%;
  margin-bottom: 5px;

  .input-label-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0 0 0 10px;
      color: $character-limit-warning;
      font-size: 14px;
    }

    .char-limit-exceeded {
      color: $error-text-color;
    }
  }

  .wilderNet-input {
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 10px;
    border-radius: 8px;
    border: 1px solid $text;
    background-color: $text-box-fill;
    margin: 5px 0 0;
  }

  & .wilderNet-input:focus {
    // border: 1px solid $secondary-color;
    outline: none;
  }

  & .wilderNet-input:disabled {
    filter: brightness(75%)
  }

  & .wilderNet-checkbox-input {
    margin: 0 5px 0 0;
  }


  & .input-error {
    p {
      color: $error-box-outline
    }

    border: 1px solid $error-box-outline;
    color: $error-box-outline;
    background-color: $error-box-fill;
  }
}

.input-checkbox {
  display: flex;
  gap: 4px;
}

.input-error-message {
  color: $error-box-outline;
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 5px;
  height: 18px;
  font-size: 12px;

  &>* {
    color: $error-box-outline;
  }
}