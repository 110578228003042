// * Mobile Default
.blog-card-small {
  position: relative;
  display: flex;
  width: 176.5px;
  height: 205px;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 10px;
  background: $accent-color;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  margin: 0 8px 16px 8px;

  // FLoating Action WNButton Styling
  .fab-new-tab {
    position: absolute;
    scale: 65%;
    top: -3px;
    right: -3px;
    background-color: hsla(180, 25%, 66%, 20%);
    z-index: 50;

    &:hover {
      background-color: hsl(180, 35%, 62%, 35%);
    }
  }

  // Card
  .blog-card-button {
    height: 100%;
    width: 100%;
  }

  .blog-card__img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
    background: $character-limit-warning;
  }

  .blog-card__content {
    height: 50%;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    & > p {
      width: 100%;
      height: 100%;
      margin: 5px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h5 {
      margin: 0px 5px;
    }

    .blog-card__desc {
      font-size: 1rem;
      color: #777;
      margin: 10px 0;
    }
  }
}

// // * Tablet
// @media (min-width: 641px) {
//   .blog-card {
//     & .next-layer {
//       display: flex;
//       max-width: 360px;
//       flex-direction: column;
//       align-items: flex-end;
//       // gap: 8px;
//       border-radius: 10px;
//       background: $accent-color;
//       box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
//       margin: 40px;
//     }

//     .blog-card__img {
//       width: 100%;
//       height: 200px;
//       object-fit: cover;
//       border-radius: 10px 10px 0px 0px;
//       background: #a7a7a7;
//     }

//     .blog-card__content {
//       display: flex;
//       padding: 8px 16px 16px 16px;
//       flex-direction: column;
//       align-items: flex-start;
//       gap: 16px;
//       align-self: stretch;
//     }
//   }
// }

// // * Desktop
// @media only screen and (min-width: 1030px) {
//   .blog-card {
//     & .next-layer {
//       display: flex;
//       max-width: 360px;
//       flex-direction: column;
//       align-items: flex-end;
//       // gap: 8px;
//       border-radius: 10px;
//       background: $accent-color;
//       box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
//       margin: 40px;
//     }
//   }
// }
