// * Mobile Default
.blog-review-page {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .blog-review-heading {
    margin: 0 50px 16px;
    text-align: center;
  }

  .blog-review-form {
    padding: 0 10px;
  }
}


// Tablet & Desktop
@media only screen and (min-width: 641px) {

  .blog-review-form {
    width: 100%;
    max-width: 641px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: calc((100% - 641px) / 2);
    padding-right: calc((100% - 641px) / 2);
  }

}