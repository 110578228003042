.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tag-list-home-page {
  width: 100%;
  display: flex;
  justify-content: center;
}