.success-modal-overlay {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 85%;
  max-height: 95%;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border-radius: 10px;
  border: $primary-color 2px solid;
  background-color: $light-background;
}

.success-modal {
  background-color: $light-background;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > * {
    text-align: center;
  }
}

.text-success {
  text-align: center;
  color: $primary-color;
  text-align: center;
}

.modal-button-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  button {
    height: 2.35em;
    width: fit-content;
    margin: 15px 0px 0px 5px;
    padding: 10px 20px;
    font-size: large;
    white-space: nowrap;
    display: flex;
    border-radius: 5px;
    border: none;
    color: $light-background;
    background-color: $primary-color;
    cursor: pointer;
  }
}

.hide-modal {
  visibility: hidden;
}

/*
	Green chackmark styling and animation
*/

svg {
  width: 100px;
  display: block;
  margin: 0 auto;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.95s 0.35s ease-in-out forwards;
  animation: dash 0.95s 0.35s ease-in-out forwards;
}

.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.95s 0.35s ease-in-out forwards;
  animation: dash-check 0.95s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

.box00 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
