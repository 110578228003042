// mobile
.profile-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .profile-page-component {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .edit-profile-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .edit-profile-button:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }

  .feature-coming-soon {
    display: flex;
    justify-content: center;
  }

  .profile-img {
    width: 15vw;
    min-width: 150px;
    height: 15vw;
    min-height: 150px;
    border-radius: 10px;
    border: 1px solid #000;
    object-fit: cover;
    cursor: pointer;
    margin-bottom: 15px;
  }

  .profile-img:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .save-changes-button,
  .cancel-changes-button {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
  }

  .save-changes-button:hover {
    opacity: 0.8;
  }

  .profile-picture-input {
    display: none;
  }

  textarea {
    border: none;
    background: transparent;
    outline: none;
    resize: none;
    overflow: hidden;
    width: 80vw;
    height: auto;
    padding: 0;
    margin: 0;
    line-height: inherit;
    text-align: center;
    text-wrap: balance;
    cursor: pointer;
    font-family: 'Avenir', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }

  textarea:hover {
    opacity: 0.5;
  }

  /* Remove scrollbar in some browsers */
  textarea::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar in Chrome, Safari, etc. */
  }

  /* Remove scrollbar in Firefox */
  textarea {
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
  }

  /*******************************************************
     Blog Display
    *******************************************************/

  .profile__blog-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-top: none;
    border-bottom: 1px solid $post-content-box;
    margin-bottom: 10px;
    width: 100%;
  }

  .interactions-btn,
  .saved-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &.active {
      font-weight: bold;
      border-bottom: 1px solid $primary-color;
    }
  }

  .blog-list-title {
    display: none;
  }

  .blog-displays {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  .blog-list-results {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100vw;
    padding: 20px;
    box-sizing: border-box;
  }

  .card-display__interactions,
  .card-display__saved {
    position: relative;
    left: 50vw;
    box-sizing: border-box;
  }

  .card-display__saved---fullscreen {
    display: none;
  }
}

/*******************************************************
    Desktop Settings
  *******************************************************/

@media only screen and (min-width: 1030px) {
  .profile-page {
    .blog-displays {
      position: static;
      width: 50vw;
    }

    .blog-list-results {
      width: fit-content;
      padding: 0;
    }

    .card-display__interactions {
      position: static;
      padding: 20px;
    }

    .card-display__saved {
      display: none;
    }

    .card-display__saved---fullscreen {
      display: block;
      padding: 10px;
    }

    .profile__blog-filter {
      display: none;
    }

    textarea {
      width: 30vw;
    }

    .blog-list-title {
      display: flex;
    }
  }
}