@import '../../variables';

// * Mobile Default
.blog-review-list-wrapper {

  h4 {
    text-align: center;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-left: 0;
    gap: 8px;
  }
}

// * Tablet
@media (min-width: 641px) {}

// * Desktop
@media only screen and (min-width: 1030px) {}