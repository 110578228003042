// mobile default
.login-signup-page {
  width: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

// desktop
@media only screen and (min-width: 1030px) {
  .login-signup-page {
    display: flex;
    background-size: 45%;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    flex-direction: row;
  }
}
