.create-profile-page {
  background-image: url('../images/Leaf_Background.png');
  background-size: cover;

  height: 98%;
  width: 90%;
  padding: 0% 5%;

  display: flex;
  align-items: center;
  flex-direction: column;


}

.create-profile-component {
  min-height: 95%;
  width: 90%;
  max-width: 500px;
  padding: 0% 5% 5% 5%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: 1px;
}

.create-update-profile-form {
  display: flex;
  width: 100%;
  max-width: 641px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &>.image-section {
    max-width: 150px;
  }

}

.create-profile-buttons {
  display: flex;
  gap: 10px;
  align-self: flex-end;
}



// tablet
@media (min-width: 641px) {
  .create-profile-component {
    background-color: $secondary-content;
    min-height: 40%;
    max-height: 100%;
    border-radius: 10px;
    margin: 5% 3%;
    padding: 24px;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .create-update-profile-form {
    margin-left: calc((100% - 641px) / 2);
    margin-right: calc((100% - 641px) / 2);
  }

  .login-signup-title {
    margin: 10px;
    text-align: center;
  }
}


@media only screen and (min-width: 1030px) {
  .create-profile-page {
    height: 100%;
    // width: 100%;

    padding: 0;
    display: flex;
    background-size: 40%;
    flex-direction: row;
  }

  .create-profile-component {
    padding: 2%;
    width: 36%;
    max-width: 40%;
    min-height: 0%;
    max-height: 80%;
  }
}