.blog-review-newsfeed-card {

  .blog-card-content {
    height: 36%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;
    background-color: $secondary-content;
  }
}