.side-drawer-button {
  background-color: transparent;
  width: 92%;
  border-radius: 20px;
  text-align: center;
  font-size: large;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  margin: 0 10px 8px;
  cursor: pointer;
  border: none;
  color: black;
  align-items: center;
}

.side-drawer-button:hover,
.side-drawer-button.selected {
  background-color: $accent-color;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.side-drawer-button:focus .side-drawer-button:active {
  outline: none;
}

.nav-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 20px;
  width: 20px;
  margin: 10px;
  border: none;
  text-align: center;
}

.nav-icon.home {
  background-image: url('../../images/nav-icons/HomeIcon.svg');
}

.nav-icon.notifications {
  background-image: url('../../images/nav-icons/NotificationsIcon.svg');
}

.nav-icon.upload {
  background-image: url('../../images/nav-icons/UploadIcon.svg');
}

.nav-icon.donate {
  background-image: url('../../images/nav-icons/DonateIcon.svg');
}

.nav-icon.contact-us-icon {
  background-image: url('../../images/nav-icons/ContactUsIcon.svg');
}

.nav-icon.newsfeed {
  background-image: url('../../images/nav-icons/NewsFeedIcon.svg');
}


.nav-icon.profile {
  background-image: url('../../images/nav-icons/ProfileIcon.svg');
}

.nav-icon.about {
  background-image: url('../../images/nav-icons/AboutUsIcon.svg');
}

.nav-icon.admin {
  background-image: url('../../images/nav-icons/AdminIcon.svg');
}

.side-drawer-button-text {
  display: flex;
  align-items: center;
}