.blogs-preview {
  display: none;
}

@media only screen and (min-width: 1030px) {
  .blogs-preview {
    width: 60%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    z-index: 100;
    overflow: auto;
    background-color: $secondary-content;
    box-shadow: inset 19px 0 10px -15px rgba(0, 0, 0, 0.8),
      inset -18px 0 10px -15px rgba(0, 0, 0, 0.8);
  }
}

.blog-preview-message {
  width: 80%;
  height: 8vh;
  margin: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: $secondary-content;
  z-index: 1;
}

.scroll-container {
  width: 95.5%;
  height: 40vh;
  min-height: 260px;
  padding: 2%;
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  z-index: 5;
  box-shadow: inset 17px 0 10px -15px rgba(0, 0, 0, 0.8),
    inset -18px 0 10px -15px rgba(0, 0, 0, 0.8);
  background-color: $secondary-content;
}

.scroll-content__top,
.scroll-content__bottom {
  height: 100%;

  &>* {
    box-shadow: 4px 10px 10px 4px rgba(0, 0, 0, 0.2);
  }
}

.scroll-content__top {
  display: flex;
  animation: scrollDisplay-top 10s linear infinite;

  &>* {
    box-shadow: 4px 10px 10px 4px rgba(0, 0, 0, 0.2);
  }
}

.scroll-content__bottom {
  display: flex;
  animation: scrollDisplay-bottom 15s linear infinite;
}

@keyframes scrollDisplay-top {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-25%);
  }
}

@keyframes scrollDisplay-bottom {
  from {
    transform: translateX(-25%);
  }

  to {
    transform: translateX(0%);
  }
}