.your-interests-section {
  height: fit-content;
  max-height: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.selected-interests-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
  gap: 10px;
  width: 95%;
  min-height: 45px;

  flex-wrap: wrap;
  overflow-y: hidden;
}

.interest-card {
  height: 40px;
  display: flex;
  background-color: $primary-color;
  color: white;
  border-radius: 10px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.remove-interest-icon {
  margin-left: 8px;
  color: white;
  font-size: 20px;
}
